@import '../../../UI/global.module.scss';

.container {
	width: 427px;
	max-width: 427px;
	border-radius: 24px;
	overflow: hidden;
	box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.15), 0px 1px 13px 0px rgba(0, 0, 0, 0.05);
	cursor: pointer;

	.containerImg {
		width: 100%;
		height: 214px;
		display: flex;
    justify-content: flex-end;
    align-items: center;
		overflow: hidden;
	}

	.image {
		width: 100%;
	}

	.details {
		display: flex;
		flex-direction: column;
		padding: 32px 24px 24px;
		gap: 18px;
	}
	
	.text {
		display: flex;
		flex-direction: column;
		gap: 16px;
	}

	.title {
		font-size: 20px;
		font-family: NT Bau;
		font-weight: 400;
		line-height: 24px;
		letter-spacing: -0.5px;
		color: #468EFF;
	}
	
	.description {
		display: flex;
		min-height: 95px;
		font-size: 24px;
		font-family: Roca One;
		font-weight: 700;
		line-height: 30px;
		letter-spacing: 0.8px;

	}

	.button {
		display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 8px;
	}

	.moreDetails {
		font-size: 18px;
		font-family: NT Bau;
		font-weight: 400;
		line-height: 24px;
		letter-spacing: -0.5px;
	}
}


@media only screen and (max-width: 768px) {
	.container {
		width: 100%;
		max-width: 100%;
		border-radius: 16px;
	
		.containerImg {
			height: 167px;
		}
	
		.details {
			padding: 20px;
			gap: 36px;
		}
		
		.text {
			display: flex;
			flex-direction: column;
			gap: 12px;
		}
	
		.title {
			font-size: 16px;
			line-height: 22px;
			letter-spacing: -0.5px;
		}
		
		.description {
			font-size: 20px;
			line-height: 25px;
			letter-spacing: 0.6px;
			min-height: auto;

			display: -webkit-box;
			-webkit-box-orient: vertical;
			-webkit-line-clamp: 2;
			overflow: hidden;
			text-overflow: ellipsis;
			max-width: 100%;
		}
	
		.button {
			display: flex;
			justify-content: flex-start;
			align-items: center;
			gap: 8px;

			& svg {
				transform: scale(0.75);
			}
		}
	
		.moreDetails {
			font-size: 14px;
			line-height: 20px;
			letter-spacing: -0.5px;
		}

	}
}